<template>
  <div class="d-flex justify-content-around">
    <small>Temp(ํC) : <u><b>{{ af_Temp }}</b></u></small>
    <small>Pulse(/min) : <u><b>{{ af_Pulse }}</b></u></small>
    <small>RR(/min) : <u><b>{{ af_RR }}</b></u></small>
    <small>BP(mm.Hg.) : <u><b>{{ af_BP }}</b></u></small>
    <small>O2Sat(%) : <u><b>{{ af_O2Sat }}</b></u></small>
    <small>BW(kg) : <u><b>{{ af_BW }}</b></u></small>
    <small>HT(cm.) : <u><b>{{ af_HT }}</b></u></small>
    <small>OFC(cm.) : <u><b>{{ af_OFC }}</b></u></small>
    <small>BMI(kg/m2) : <u><b>{{ af_BMI }}</b></u></small>
  </div>
</template>

<script>

export default {
  props: {
    service: String,
    txn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      af_Temp: '',
      af_Pulse: '',
      af_RR: '',
      af_BP: '',
      af_O2Sat: '',
      af_BW: '',
      af_HT: '',
      af_OFC: '',
      af_BMI: '',
      af_PresentIllness: '',
      af_ChiefComplaint: '',
      af_ctasid: '',
    }
  },
  created() {
    this.loadDate()
  },
  methods: {
    loadDate() {
      this.$http({
        url: `/api/EMR/initialServiceDetail/${'Old'}/${this.txn}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        // console.log('date vital', data)
        if (data.initialID !== undefined) {
          this.af_Temp = data.temp
          this.af_Pulse = data.pulse
          this.af_RR = data.rr
          this.af_BP = data.bp
          this.af_O2Sat = data.o2Sat
          this.af_BW = data.bw
          this.af_HT = data.ht
          this.af_OFC = data.ofc
          this.af_BMI = data.bmi
          this.af_PresentIllness = data.presentIllness
          this.af_ChiefComplaint = data.chiefComplaint
          this.af_ctasid = data.ctasDetail.ctasid
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

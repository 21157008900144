<template>
  <div>
    <b-tabs
      content-class="mt-1"
      fill
      lazy
    >
      <b-tab
        title="All"
        active
      >
        <b-row class="w-100 mb-2 mt-2">
          <b-col cols="12">
            <vitalSign
              :key="5"
              service="5"
              :txn="txn"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="pr-custom-5"
          >
            <encounter
              :encounter="encounter"
              class="mb-2"
            />
            <past-history
              :history="history"
              class="mb-2"
            />
            <ob :obs="obs" />
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="pl-custom-5"
          >
            <icd10 :i-c-d10="ICD10" />
            <order :order="order" />
            <followup />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Record">
        <encounter
          :encounter="encounter"
          class="mb-2"
        />
        <past-history
          :history="history"
          class="mb-2"
        />
        <ob :obs="obs" />
      </b-tab>
      <b-tab title="ICD10">
        <icd10 :i-c-d10="ICD10" />
      </b-tab>
      <b-tab title="Order">
        <order :order="order" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import followup from './components/followup.vue'
import encounter from './components/encounter.vue'
import pastHistory from './components/pastHistory.vue'
import icd10 from './components/ICD10.vue'
import order from './components/order.vue'
import ob from './components/ob.vue'
import vitalSign from './components/vitalSign.vue'

export default {
  components: {
    followup,
    encounter,
    pastHistory,
    icd10,
    order,
    ob,
    vitalSign,
  },
  props: {
    txn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      encounter: { chiefComplaint: '', presentIllness: '', nurseC: '' },
      history: null,
      physicalExamination: { list: [], note: '' },
      obs: [],
      image: null,
      ICD10: [],
      order: { list: {}, note: '' },
    }
  },
  async created() {
    await this.fetchICD10()
    await this.fetchOrder()
    await this.fetchRecord()
  },
  methods: {
    async fetchOrder() {
      await this.$http({
        url: `/api/EMR/opd/pt_message/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        [this.order.note] = data.data_pt.msg
        this.order.note = JSON.parse(this.order.note)
      })
      await this.$http({
        url: `/api/EMR/opd/lists/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.order.list = data
      })
    },
    async fetchICD10() {
      await this.$http({
        url: 'api/EMR/FindNewTxnICD',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.ICD10 = data
      })
    },
    async fetchRecord() {
      await this.$http({
        url: 'api/EMR/allObGynForm',
        method: 'POST',
        data: {
          ServiceID: 0,
          TXN: parseInt(this.txn, 10),
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        if (data.patientHistoryFormRecord.nursePatientHistory != null) {
          this.encounter.nurseC = data.patientHistoryFormRecord.nursePatientHistory.chiefComplaint
        }
        if (data.patientHistoryFormRecord.doctorPatientHistory != null) {
          this.encounter.chiefComplaint = data.patientHistoryFormRecord.doctorPatientHistory.chiefComplaint
          this.encounter.presentIllness = data.patientHistoryFormRecord.doctorPatientHistory.presentIllness
        }
        this.obs = data.obGynFormRecord
        this.history = data.historyFormRecord
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

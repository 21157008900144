<template>
  <div class="mb-1">
    <!-- <b-card class="mb-1"> -->
    <h5 class="text-primary">
      <feather-icon
        icon="ClipboardIcon"
        class="mr-50"
      /><b>ประวัติ ICD-10</b>
    </h5>
    <hr>
    <b-table
      striped
      hover
      :items="ICD10"
      :fields="fields"
      :head-variant="'dark'"
    />
    <!-- </b-card> -->
  </div>
</template>

<script>
export default {
  props: {
    ICD10: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'icdGroup',
          label: 'กลุ่มอาการ',
        },
        {
          key: 'icdCode',
          label: 'รหัส',
        },
        {
          key: 'icdCdesc',
          label: 'อาการ',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
</style>

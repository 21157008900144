<template>
  <div>
    <h5 class="text-primary">
      <feather-icon
        icon="UserCheckIcon"
        class="mr-50"
      /><b>Physical Examination</b>
    </h5>
    <hr>
    <b-row
      v-for="(item, index) in obs"
      :key="index"
      class="mb-1 align-items-center"
    >
      <b-col
        cols="12"
        xl="1"
      >
        <span><b>{{ item.dataObGyn }}</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="11"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ item.comment }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    obs: Array,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  .new-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .tab-text{
    text-indent: 15px;
  }
</style>

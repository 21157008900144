<template>
  <div>
    <h5 class="text-primary">
      <feather-icon
        icon="CheckSquareIcon"
        class="mr-50"
      /><b>Check Up</b>
    </h5>
    <hr>
    <b-row
      v-for="(item, index) in checkups"
      :key="index"
      class="mb-1 align-items-center"
    >
      <b-col
        cols="12"
        class="mb-1"
      >
        <span><b>{{ item.dataCheckup }}</b></span>
      </b-col>
      <b-col
        cols="12"
        class="mb-1 mb-xl-0"
      >
        <b-form-checkbox
          :checked="item.status"
          disabled
        >
          {{ (item.dataCheckup == 'CRX' || item.dataCheckup == 'U/S') ? 'Unremarkable study' : 'Normal' }}
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
          :checked="!item.status && item.status != null"
          disabled
        >
          Abnormal
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="9"
        xl="8"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ item.comment }}
        </div>
      </b-col>
    </b-row>
    <!-- <b-row class="mb-1 align-items-center">
      <b-col cols="12" class="mb-1">
        <span><b>header</b></span>
      </b-col>
      <b-col cols="12" class="mb-ct-5">
        <b-form-checkbox :checked="true" disabled>Unremarkable study</b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox :checked="false" disabled>Abnormal</b-form-checkbox>
      </b-col>
      <b-col cols="9" xl="8" class="d-flex align-items-end">
        <div class="new-line tab-text">The self-study lessons in this section are written and organised according to the levels of the Common European Framework of Reference for languages (CEFR).</div>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
export default {
  props: {
    checkups: Array,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  .mb-ct-5{
    margin-bottom: 5px;
  }
  .tab-text{
    text-indent: 15px;
  }
</style>

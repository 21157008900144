<template>
  <div>
    <b-card class="mb-1">
      <h5 class="text-primary">
        <feather-icon
          icon="CalendarIcon"
          class="mr-50"
        />
        <b>Follow Up</b>
      </h5>
      <hr>
      <b-row>
        <b-col cols="12">
          <span
            class="f-16"
            style="margin-left: 15px"
          >
            <b>{{ dateFollow }} เวลา {{ timeStartFollow }}-{{ timeEndFollow }}</b>
          </span>
        </b-col>
        <b-col cols="12">
          <span
            class="f-16"
            style="margin-left: 15px"
          >
            <b>{{ servicetext }}</b>
          </span>
        </b-col>
        <hr>
        <b-col cols="12">
          <span
            class="f-16"
            style="margin-left: 15px; white-space: pre-wrap;"
          >
            {{ noteFollow }}
          </span>
        </b-col>
        <b-col cols="12">
          <span
            class="f-16"
            style="margin-left: 15px; white-space: pre-wrap;"
          >
            {{ appNote }}
          </span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    txn: {
      type: undefined,
      default: () => '',
    },
    hn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      dateFollow: null,
      noteFollow: null,
      clinicDate: null,
      doc: null,
      appNote: null,
      months_thai: [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม',
        'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
      ],
      servicetext: null,
      timeStartFollow: null,
      timeEndFollow: null,
    }
  },
  async created() {
    await this.fetchData()
    await this.$http({
      url: `http://api159.sriphat.com/api/Appointment/listOldAppointment/${this.hn}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
      },
    }).then(response => {
      this.servicetext = response.data[0].comeForText
      this.timeStartFollow = response.data[0].timE2
      this.timeEndFollow = response.data[0].timE1
    })
  },
  methods: {
    async fetchData() {
      await this.$http({
        url: `api/EMR/FollowupNote/${this.txn}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.noteFollow = data.note
        if (data.appDate !== '') {
          const date = new Date(data.appDate)
          this.dateFollow = `${date.getDate()} ${this.months_thai[date.getMonth()]} ${date.getFullYear() + 543}`
        }
      })
      await this.$http({
        url: `api/EMR/fromreg/viewer/${this.txn}`,
        method: 'GET',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
      }).then(response => {
        this.clinicDate = response.data.date
        this.clinicDate = this.clinicDate.split('-').reverse().join('-')
        this.doc = response.data.doc
      })
      await this.$http({
        url: 'http://api159.sriphat.com/api/Appointment/OldAppointmentByFilter ',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
        data: {
          DOC: this.doc,
          DateApp: this.clinicDate,
          HN: this.hn,
        },
      }).then(response => {
        this.appNote = response.data[0].appNote
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .label-header{
    font-size: 22px;
    display: block;
  }
  .under-line{
    border-bottom: 1px solid rgb(180, 180, 180);
    padding: 0px 7px 0px 7px;
  }
  .new-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .m-l-15{
    margin-left: 15px;
  }
</style>

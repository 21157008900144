<template>
  <div>
    <h6 class="text-primary">
      <b>Elimination</b>
    </h6>
    <b-row
      v-for="(item, index) in eliminations"
      :key="index"
      align-v="center"
    >
      <b-col cols="12">
        <span><b>{{ item.dataElimination }}</b></span>
      </b-col>
      <b-col
        cols="3"
        class="d-flex mb-ct-8"
      >
        <div class="new-line text-center">
          {{ item.number }}
        </div>
        <span>times</span>
      </b-col>
      <b-col
        cols="12"
        xl="9"
        class="d-flex flex-wrap px-0"
      >
        <b-col
          cols="12"
          class="mb-ct-8"
        >
          <b-form-checkbox
            :checked="item.status"
            disabled
          >
            WNL
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          class="d-flex flex-wrap px-0"
        >
          <b-col
            cols="3"
            xl="4"
          >
            <b-form-checkbox
              :checked="!item.status && item.status != null"
              disabled
            >
              Not WNL
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="9"
            xl="8"
            class="px-0"
          >
            <div class="new-line tab-text">
              {{ item.comment }}
            </div>
          </b-col>
          <!-- <b-form-checkbox :checked="true" disabled>Not WNL</b-form-checkbox> -->
        </b-col>
      </b-col>
      <!-- <b-col cols="12" xl="2" class="mb-1 mb-xl-0">
        <b-form-checkbox :checked="true" disabled>WNL</b-form-checkbox>
      </b-col>
      <b-col cols="3" xl="2">
        <b-form-checkbox :checked="false" disabled>Not WNL</b-form-checkbox>
      </b-col>
      <b-col cols="9" xl="8" class="d-flex align-items-end">
        <div class="new-line tab-text">comment</div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  props: { eliminations: Array },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  .mb-ct-8{
    margin-bottom: 8px;
  }
  .new-line{
    width: 150px;
    white-space: pre-wrap;
  }
  .tab-text{
    text-indent: 15px;
  }
</style>

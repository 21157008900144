<template>
  <div>
    <b-tabs
      content-class="mt-1"
      fill
      lazy
    >
      <b-tab
        title="All"
        active
      >
        <b-row class="w-100 mb-2 mt-2">
          <b-col cols="12">
            <vitalSign
              :key="5"
              service="5"
              :txn="txn"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="pr-custom-5"
          >
            <!-- <b-card class="custom-general mb-1"> -->
            <encounter
              :encounter="encounter"
              class="mb-2"
            />
            <past-history
              :history="history"
              class="mb-2"
            />
            <physical-examination
              :physical-examination="physicalExamination"
              class="mb-2"
            />
            <check-up :checkups="checkups" />
            <!-- </b-card> -->
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="pl-custom-5"
          >
            <icd10 :i-c-d10="ICD10" />
            <order :order="order" />
            <followup />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Record">
        <encounter
          :encounter="encounter"
          class="mb-2"
        />
        <past-history
          :history="history"
          class="mb-2"
        />
        <physical-examination
          :physical-examination="physicalExamination"
          class="mb-2"
        />
        <check-up :checkups="checkups" />
      </b-tab>
      <b-tab title="ICD10">
        <icd10 :i-c-d10="ICD10" />
      </b-tab>
      <b-tab title="Order">
        <order :order="order" />
      </b-tab>
    </b-tabs>
    <!-- <b-row>
      <b-col cols="12" lg="6" class="pr-custom-5">
        <b-card class="custom-general">
          <encounter class="mb-2"></encounter>
          <past-history class="mb-2"></past-history>
          <physical-examination class="mb-2"></physical-examination>
          <check-up class="mb-2"></check-up>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" class="pl-custom-5">
        <icd10 :ICD10="ICD10"></icd10>
        <order :order="order"></order>
        <followup></followup>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import followup from './components/followup.vue'
import encounter from './components/encounter.vue'
import pastHistory from './components/pastHistory.vue'
import physicalExamination from './components/physicalExamination.vue'
import icd10 from './components/ICD10.vue'
import order from './components/order.vue'
import checkUp from './components/checkup.vue'
import vitalSign from './components/vitalSign.vue'
// import images from './components/images.vue'

export default {
  components: {
    followup,
    encounter,
    pastHistory,
    physicalExamination,
    icd10,
    order,
    checkUp,
    vitalSign,
    // images,
  },
  props: {
    txn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      encounter: { chiefComplaint: '', presentIllness: '', nurseC: '' },
      history: null,
      physicalExamination: { list: [], note: '' },
      checkups: [],
      image: null,
      ICD10: [],
      order: { list: {}, note: '' },
    }
  },
  async created() {
    await this.fetchICD10()
    await this.fetchOrder()
    await this.fetchRecord()
  },
  methods: {
    async fetchICD10() {
      await this.$http({
        // url: 'api/EMR/FindNewTxnICD',
        url: `/api/EMR/FindNewTxnICD/${this.txn}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.ICD10 = data
      })
    },
    async fetchOrder() {
      await this.$http({
        url: `/api/EMR/opd/pt_message/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        [this.order.note] = data.data_pt.msg
        this.order.note = JSON.parse(this.order.note)
      })
      await this.$http({
        url: `/api/EMR/opd/lists/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.order.list = data
      })
    },
    async fetchRecord() {
      await this.$http({
        url: '/api/EMR/allCheckupForm',
        method: 'POST',
        data: {
          // "PatientID" : parseInt(this.$store.getters.getPdetail.hn),
          ServiceID: 0,
          // "ServiceID" : this.serviceId,
          // "TXN" : parseInt(this.$store.getters.getPdetail.txn),
          TXN: parseInt(this.txn, 10),
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        if (data.patientHistoryFormRecord.nursePatientHistory != null) {
          this.encounter.nurseC = data.patientHistoryFormRecord.nursePatientHistory.chiefComplaint
        }
        if (data.patientHistoryFormRecord.doctorPatientHistory != null) {
          this.encounter.chiefComplaint = data.patientHistoryFormRecord.doctorPatientHistory.chiefComplaint
          this.encounter.presentIllness = data.patientHistoryFormRecord.doctorPatientHistory.presentIllness
        }
        this.checkups = data.checkupFormRecord
        this.history = data.historyFormRecord
        this.physicalExamination.list = data.peFormRecord.pE_Checkbox
        this.physicalExamination.note = data.peFormRecord.pE_Text[0].comment
        // this.images = data.drawingFormRecord
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-tabs
      content-class="mt-1"
      fill
      lazy
    >
      <b-tab
        title="All"
        active
      >
        <b-row class="w-100 mb-2 mt-2">
          <b-col cols="12">
            <vitalSign
              :key="5"
              service="5"
              :txn="txn"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="pr-custom-5"
          >
            <!-- <b-card class="custom-general mb-1"> -->
            <encounter
              :encounter="encounter"
              class="mb-2"
            />
            <past-history
              :history="history"
              class="mb-2"
            />
            <physical-examination
              :physical-examination="physicalExamination"
              class="mb-2"
            />
            <eliminations />
            <!-- </b-card> -->
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="pl-custom-5"
          >
            <icd10 :i-c-d10="ICD10" />
            <order :order="order" />
            <followup
              :txn="txn"
              :hn="hn"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Record">
        <encounter
          :encounter="encounter"
          class="mb-2"
        />
        <past-history
          :history="history"
          class="mb-2"
        />
        <physical-examination
          :physical-examination="physicalExamination"
          class="mb-2"
        />
        <eliminations />
      </b-tab>
      <b-tab title="ICD10">
        <icd10 :i-c-d10="ICD10" />
      </b-tab>
      <b-tab title="Order">
        <order :order="order" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import followup from './components/followup.vue'
import encounter from './components/encounter.vue'
import pastHistory from './components/pastHistory.vue'
import physicalExamination from './components/physicalExamination.vue'
import icd10 from './components/ICD10.vue'
import order from './components/order.vue'
import eliminations from './components/well-baby/elimination.vue'
import vitalSign from './components/vitalSign.vue'

export default {
  components: {
    followup,
    encounter,
    pastHistory,
    physicalExamination,
    icd10,
    order,
    eliminations,
    vitalSign,
  },
  props: {
    txn: {
      type: undefined,
      default: () => '',
    },
    hn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      encounter: { chiefComplaint: '', presentIllness: '', nurseC: '' },
      history: null,
      physicalExamination: { list: [], note: '' },
      image: null,
      ICD10: [],
      order: { list: {}, note: '' },
      followup: {},
    }
  },
  watch: {
    serviceId() {
      this.api()
    },
  },
  async created() {
    await this.fetchICD10()
    await this.fetchOrder()
    await this.fetchRecord()
  },
  methods: {
    showImage(img) {
      this.imgPopup = true
      this.image = `data:image/png;base64, ${img}`
    },
    async fetchOrder() {
      await this.$http({
        url: `/api/EMR/opd/pt_message/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        [this.order.note] = data.data_pt.msg
        if (this.order.note !== '') {
          this.order.note = JSON.parse(this.order.note)
        } else {
          this.order.note = ''
        }
      })
      await this.$http({
        url: `/api/EMR/opd/lists/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.order.list = data
      })
    },
    async fetchICD10() {
      await this.$http({
        url: `/api/EMR/FindNewTxnICD/${this.txn}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.ICD10 = data
      })
    },
    async fetchRecord() {
      await this.$http({
        url: 'api/EMR/allGeneralForm',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          ServiceID: 0,
          TXN: parseInt(this.txn, 10),
        },
      }).then(({ data }) => {
        if (data.patientHistoryFormRecord.nursePatientHistory != null) {
          this.encounter.nurseC = data.patientHistoryFormRecord.nursePatientHistory.chiefComplaint
        }
        if (data.patientHistoryFormRecord.doctorPatientHistory != null) {
          this.encounter.chiefComplaint = data.patientHistoryFormRecord.doctorPatientHistory.chiefComplaint
          this.encounter.presentIllness = data.patientHistoryFormRecord.doctorPatientHistory.presentIllness
        }
        this.history = data.historyFormRecord
        this.physicalExamination.list = data.peFormRecord.pE_Checkbox
        this.physicalExamination.note = data.peFormRecord.pE_Text[0].comment
      })
    },
  },
}
</script>

<style lang="scss">
  .custom-general .card-body {
    padding: 20px;
  }
</style>

<style lang="scss" scoped>
  .input-viewer{
    // border-bottom: 1px solid black;
    border-bottom: 1px solid rgb(180, 180, 180);
    padding: 0px 7px 0px 7px;
  }
  .text-cen{
    text-align: center;
  }
  .display-inblock{
    display: inline-block;
  }
  .center-box{
    margin: 0px auto;
  }
  .pointer{
    cursor: pointer;
  }
  .center-popup{
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .header{
    font-size: 25px;
  }
  .shadow-dev {
    -webkit-box-shadow: 0px 7px 24px -3px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 7px 24px -3px rgba(0, 0, 0, 0.21);
    box-shadow: 0px, 7px 24px -3px rgba(0, 0, 0, 0.21);
  }
  .paper{
    background-color: white;
    padding: 20px;
    border-radius: 10px
  }
  .under-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .pl-custom-5{
    padding-left: 5px;
  }
  .pr-custom-5{
    padding-right: 5px;
  }
  @media only screen and (max-width: 991px) {
    .pl-custom-5{
      padding-left: 14px;
    }
    .pr-custom-5{
      padding-right: 14px;
    }
  }
</style>

<template>
  <div>
    <h5 class="text-primary">
      <feather-icon
        icon="LogInIcon"
        class="mr-50"
      /><b>Encouter</b>
    </h5>
    <hr>
    <b-row>
      <b-col
        cols="12"
        xl="3"
      >
        <span><b> Nurse Chief Complaint</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="9"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ encounter.nurseC }}
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mb-2">
      <b-col
        cols="12"
        xl="3"
      >
        <span><b>Chief Complaint</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="9"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ encounter.chiefComplaint }}
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="3"
      >
        <span><b>Present Illness</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="9"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ encounter.presentIllness }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    encounter: Object,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  // .under-line{
  //   border-bottom: 1px solid rgb(180, 180, 180);
  //   padding: 0px 7px 0px 7px;
  // }
  .new-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .tab-text{
    text-indent: 15px;
  }
</style>

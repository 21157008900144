<template>
  <div>
    <!-- <b-row class="mb-10" v-for="item in history" :key="item.dataHistory">
      <b-col>
        <span><b>{{item.dataHistory}}</b></span>
      </b-col>
      <b-col>
        <b-form-checkbox :checked="item.status" disabled>WNL</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox :checked="!item.status && item.status != null" disabled>Not WNL</b-form-checkbox>
      </b-col>
      <b-col>
        <div class="under-line new-line">{{item.comment}}</div>
      </b-col>
    </b-row> -->
    <h5 class="text-primary">
      <feather-icon
        icon="ClockIcon"
        class="mr-50"
      /><b>Past History</b>
    </h5>
    <hr>
    <b-row
      v-for="(item, index) in history"
      :key="index"
      class="mb-1 align-items-center"
    >
      <b-col
        cols="12"
        class=""
      >
        <span><b>{{ item.dataHistory }}</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="2"
        class="mb-1 mb-xl-0"
      >
        <b-form-checkbox
          :checked="item.status"
          disabled
        >
          WNL
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="3"
        xl="2"
      >
        <b-form-checkbox
          :checked="!item.status && item.status != null"
          disabled
        >
          Not WNL
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="9"
        xl="8"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ item.comment }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    history: Array,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  // .under-line{
  //   border-bottom: 1px solid rgb(180, 180, 180);
  //   padding: 0px 7px 0px 7px;
  // }
  .new-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .tab-text{
    text-indent: 15px;
  }
</style>

<template>
  <div class="order">
    <!-- <b-card class="mb-1"> -->
    <h5 class="text-primary">
      <feather-icon
        icon="Edit3Icon"
        class="mr-50"
      /><b>Doctor Note</b>
    </h5>
    <hr>
    <div
      v-for="(item, index) in note"
      :key="index"
      class="px-1"
    >
      <b>{{ index + 1 }}. {{ item }}</b>
    </div>
    <h5 class="text-primary mt-1">
      <feather-icon
        icon="FileTextIcon"
        class="mr-50"
      /><b>Order</b>
    </h5>
    <hr>
    <b-row class="m-0">
      <b-col
        cols="12"
        class="bg-custom-dark p-custom-7 d-flex justify-content-between"
      >
        <span class="text-white">รายการยา</span>
        <span class="text-white">จำนวน</span>
      </b-col>
      <b-col
        v-for="(item, index) in order.list.orderDrug"
        :key="index"
        cols="12"
        class="d-flex justify-content-between px-2 py-custom-5"
      >
        <div>
          <span>{{ item.name }}</span>
          <span v-if="item.sig && item.sig_add">{{ `(${item.sig}, ${item.sig_add})` }}</span>
        </div>
        <span>{{ item.qty }}</span>
      </b-col>
      <b-col
        v-if="order.list.orderDrug != null && order.list.orderDrug.length == 0"
        cols="12"
        class="d-flex justify-content-between px-4 py-custom-5"
      >
        <span>-</span>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-col
        cols="12"
        class="bg-custom-dark p-custom-7"
      >
        <span class="text-white">การตรวจทางรังสี</span>
      </b-col>
      <b-col
        v-for="(item, index) in order.list.orderXray"
        :key="index"
        cols="12"
        class="d-flex justify-content-between px-2 py-custom-5"
      >
        <span>{{ item.name }}</span>
        <!-- <span>{{ item.qty }}</span> -->
      </b-col>
      <b-col
        v-if="order.list.orderXray != null && order.list.orderXray.length == 0"
        cols="12"
        class="d-flex justify-content-between px-4 py-custom-5"
      >
        <span>-</span>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-col
        cols="12"
        class="bg-custom-dark p-custom-7"
      >
        <span class="text-white">การตรวจทางห้องปฏิบัติการ</span>
      </b-col>
      <b-col
        v-for="(item, index) in order.list.orderLabs"
        :key="index"
        cols="12"
        class="d-flex justify-content-between px-2 py-custom-5"
      >
        <span>{{ item.name }}</span>
        <!-- <span>{{ item.qty }}</span> -->
      </b-col>
      <b-col
        v-if="order.list.orderLabs != null && order.list.orderLabs.length == 0"
        cols="12"
        class="d-flex justify-content-between px-4 py-custom-5"
      >
        <span>-</span>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-col
        cols="12"
        class="bg-custom-dark p-custom-7"
      >
        <span class="text-white">รายการหัตถการ</span>
      </b-col>
      <b-col
        v-for="(item, index) in order.list.orderEtcx"
        :key="index"
        cols="12"
        class="d-flex justify-content-between px-2 py-custom-5"
      >
        <span>{{ item.name }}</span>
        <!-- <span>{{ item.qty }}</span> -->
      </b-col>
      <b-col
        v-if="order.list.orderEtcx != null && order.list.orderEtcx.length == 0"
        cols="12"
        class="d-flex justify-content-between px-4 py-custom-5"
      >
        <span>-</span>
      </b-col>
    </b-row>
    <!-- </b-card> -->
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  computed: {
    note() {
      return this.order.note
    },
  },
  created() {
    // console.log('in order', this.order)
  },
}
</script>

<style lang="scss">
  .bg-custom-dark {
    background-color: #696969;
  }
  .py-custom-5{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .p-custom-7{
    padding: 7px;
  }
</style>
